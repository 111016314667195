import React from "react";
import style from "./css/brandTotal&ProductDetails.module.css";
import { ServerTimeToFormateTime } from "../../../../api/GlobalFunctions";

export default function InvoiceDetails({ popUp, setPopUp }) {
  return (
    <div className={style.wrapper}>
      <div className={style.holder} aria-label="invoice_detail">
        <h2>Inovice Details of #{popUp.id}</h2>
        <div className={style.info_section}>
          <div className={style.detail_info}>
            <h4>Customer Details</h4>
            <div>
              <p>
                <strong>Customer Name : </strong> {popUp.name}
              </p>
              <p>
                <strong>Customer Phone : </strong> {popUp.phone}
              </p>
              <p>
                <strong>Customer Addrress : </strong> {popUp.address}
              </p>
            </div>
          </div>
          <div className={style.detail_info}>
            <h4>Order Details</h4>
            <div>
              <p>
                <strong>Status : </strong> {popUp.status}
              </p>
              <p>
                <strong>Date & Time : </strong>{" "}
                {ServerTimeToFormateTime(popUp.created_date)}
              </p>
            </div>
          </div>
        </div>
        <div className={style.table_wrapper}>
          <table>
            <thead>
              <tr>
                <th>SL</th>
                <th>Product Name</th>
                <th>Variance Name</th>
                <th>Price</th>
                <th>Discount</th>
                <th>Quantity</th>
                <th>Total Amount</th>
              </tr>
            </thead>
            <tbody>
              {popUp.products.map((item, index) => {
                return (
                  <tr key={item.id}>
                    <td>{index + 1}</td>
                    <td>{item.en_sort_title}</td>
                    <td>{item.Variance_name}</td>
                    <td>{item.price}</td>
                    <td>{item.dis_amount}</td>
                    <td>{item.quantity}</td>
                    <td>{item.quantity * item.price - item.dis_amount}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div>
          <p>Delivery Charge : {popUp.deliveryCharge}</p>
          <p>
            Total Amount : {popUp.deliveryCharge + parseInt(popUp.total_price)}
          </p>
        </div>
        <div className={style.button_Wrapper}>
          <button
            onClick={() => {
              setPopUp();
              document.body.style.overflow = "auto";
            }}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
}
