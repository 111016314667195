import { deleteCookie } from "cookies-next";
import { FaBars, FaTimes } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import logo from "./assets/anayase_logo.png";
import topbarCss from "./css/topbar.module.css";
import { selectMenuShow, setMenuShow } from "./ReduxFiles/Slices/ProductSlice";
import {
  selectUserInfo,
  setIsLogedIn,
  setUserInfo,
} from "./ReduxFiles/Slices/UserSlice";
export default function TopBar() {
  const userInfo = useSelector(selectUserInfo);
  const showMenu = useSelector(selectMenuShow);
  const myNavigate = useNavigate();
  const myDispatch = useDispatch();

  function onLogoutClick() {
    deleteCookie("anyase_admin_user");
    myNavigate("/login");
    myDispatch(setIsLogedIn(false));
    myDispatch(setUserInfo({}));
  }
  function onMenuShowClick() {
    myDispatch(setMenuShow(true));
  }
  function onMenuHideClick() {
    myDispatch(setMenuShow(false));
  }

  return (
    <div className={topbarCss.top_wrapper}>
      <div className={topbarCss.top_inner}>
        <div className={topbarCss.mobile_toggle}>
          {showMenu ? (
            <FaTimes onClick={onMenuHideClick} />
          ) : (
            <FaBars onClick={onMenuShowClick} />
          )}
        </div>
        <div className={topbarCss.top_logo}>
          <Link to={"/"}>
            <img src={logo} alt="site logo" />
          </Link>
        </div>
        <div className={topbarCss.profile}>
          <p onClick={onLogoutClick}>Logout</p>
          <p>
            <Link to={"/profile"}>{userInfo.userName}</Link>
          </p>
        </div>
      </div>
    </div>
  );
}
